import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';

import { USER_DETAILS, WORKSPACES_AND_PERMISSIONS } from '../../strings';

export interface UserContentContainerProps {
    columnContent: ReactNode;
    panelContent?: ReactNode;
}

export const UserContentContainer = ({
    columnContent,
    panelContent,
}: UserContentContainerProps) => (
    <Grid container>
        <Grid
            item
            xs={12}
            md={12}
            lg={3}
            xl={4}
            sx={(theme) => ({
                borderRight: `1px solid ${theme.palette.grey[200]}`,
                [theme.breakpoints.down('lg')]: {
                    borderRight: 'none',
                    paddingBottom: theme.spacing(3),
                },
            })}
            container
            wrap="nowrap"
            direction="column"
        >
            <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: theme.spacing(),
                    maxHeight: theme.spacing(5),
                    minHeight: theme.spacing(5),
                    borderTopLeftRadius: 4,
                    borderBottomLeftRadius: 4,
                })}
            >
                <Typography variant="h6" color="inherit">
                    {USER_DETAILS}
                </Typography>
            </Grid>
            <Grid container alignContent="flex-start">
                {columnContent}
            </Grid>
        </Grid>
        <Grid
            item
            xs={12}
            md={12}
            lg={9}
            xl={8}
            style={{ height: '100%' }}
            container
            wrap="nowrap"
            direction="column"
            sx={{ height: '100%' }}
        >
            <Grid
                item
                xs={12}
                sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    padding: theme.spacing(),
                    maxHeight: theme.spacing(5),
                    minHeight: theme.spacing(5),
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                })}
                display="flex"
                alignItems="center"
            >
                <Typography variant="h6" color="inherit">
                    {WORKSPACES_AND_PERMISSIONS}
                </Typography>
            </Grid>
            {panelContent}
        </Grid>
    </Grid>
);
