import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { getHubForCurrentOrg } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { PERMISSIONS_USERS_PAGE } from '@local/workspaces/dist/urls';
import Typography from '@mui/material/Typography/Typography';
import { Link } from 'react-router-dom';

export const WorkspaceLinkCell = ({ item, itemKey }: ListCellComponentProps) => {
    const hubCode = getHubForCurrentOrg().code;

    return (
        <Link to={`../../workspaces/${hubCode}/${item.id}/${PERMISSIONS_USERS_PAGE}`}>
            <Typography
                variant="body2"
                color="primary"
                sx={{
                    fontWeight: 500,
                    textDecoration: 'underline',
                }}
            >
                {item[itemKey]}
            </Typography>
        </Link>
    );
};
