import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { useAssignUserRoleAdminMutation } from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import type { RoleEnum } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { FAILED_TO_EDIT_USERS } from '@local/workspaces/dist/strings';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { BaseWorkspaceRoleCell } from './BaseWorkspaceRoleCell';

export const WorkspaceRoleCell = ({ item, itemKey }: ListCellComponentProps) => {
    const [roleAssignerAdmin] = useAssignUserRoleAdminMutation();
    const hubUrl = getHubUrlForCurrentOrg();
    const params = useParams();
    const { addMessage } = useMessagesContext();
    const [selectedRole, setSelectedRole] = useState<RoleEnum>(item[itemKey] as RoleEnum);
    const onChange = async (role: RoleEnum) => {
        const oldSelectedRole = role;
        try {
            setSelectedRole(role);
            await roleAssignerAdmin({
                workspaceId: item.id,
                orgId: getOrgUuidFromParams(params),
                hubUrl,
                assignRoleRequest: {
                    role: role as RoleEnum,
                    user_id: item.user_id,
                },
            }).unwrap();
        } catch (error) {
            trackError('Error updating user role', JSON.stringify(error));
            addMessage({
                message: FAILED_TO_EDIT_USERS,
                severity: 'error',
            });
            setSelectedRole(oldSelectedRole);
        }
    };

    return <BaseWorkspaceRoleCell defaultSelectedRow={selectedRole} onRoleUpdate={onChange} />;
};
